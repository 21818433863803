<script>

export default {
  components: {
    ObjectTable: () => import('@/components/ObjectTable/ObjectTable.vue'),
    StarBtn: () => import('@/components/buttons/StartBtn.vue')
  },

  async mounted() {
    const response = await this.$store.getters.fetchGet({ path: `ChatAssistantAI/GetFavoritosByPersona/${this.userId}` })
    const result = await response.json()
    console.log("mis favoritos", result)
    result.forEach(favorito => {

      const preguntaInicio = favorito.userMessage.indexOf("pregunta: ") + "pregunta: ".length;
      const preguntaFin = favorito.userMessage.indexOf("respuesta: ");
      const userAsk = favorito.userMessage.slice(preguntaInicio, preguntaFin).trim();

      const respuestaInicio =  favorito.userMessage.indexOf("respuesta: ") + "respuesta: ".length;
      const objTable = JSON.parse( favorito.userMessage.slice(respuestaInicio).trim()).result;
      
      const conversation = {
        isFavorite: favorito.isFavorite,
        id: favorito.id,
        history: [
          {
            role: 'user',
            content: userAsk
          },
          {
            role: 'assistant',
            content: favorito.assistantMessage,
            resultQuery: objTable
          }
        ]
      }
      this.conversations.push(conversation)
    })
  },
  data() {
    return {
      conversations: [],
      showMore: false
    }
  },
  computed: {
    userId() {
      return this.$store.getters.userLoggedIn.id
    }
  },
  methods: {
    async toggleIndiceFavorito( conversation ){
      if( conversation.isFavorite ) {
        conversation.isFavorite = false

        const response = await this.$store.getters
        .fetchPut({ path: `ChatAssistantAI/SetIsFavorite/${conversation.id}/${false}`})
        if( !response.ok ) {
          conversation.isFavorite = true
        }

      } else {
        conversation.isFavorite = true

        const response = await this.$store.getters
        .fetchPut({ path: `ChatAssistantAI/SetIsFavorite/${conversation.id}/${true}`})
        if( !response.ok ) {
          conversation.isFavorite = false
        }

      }

    }
  }
}

</script>

<template>
  <div class="chatbot">
    <div class="header-chatbot">
      <p class="title">Mensajes conversations</p>
    </div>

    
    <ul class="messages history" id="history" v-for="(favorito, index) in conversations" :key="index">
      <div class="conversation-wrapper">
        <header class="p-2 pb-0">
          <StarBtn  
            v-if="favorito.history[1].role == 'assistant'" 
            title="Guardar en favoritos"
            :value="favorito.isFavorite" 
            @input="toggleIndiceFavorito(favorito)"/>
        </header>
        <div class="conversation-scale">
          <li v-for="({ role, content, resultQuery }) in favorito.history" :key="content" class="message"
            :class="role == 'user' ? 'm-user' : 'm-assistant'">
            <div class="message-text">
              <article v-if="content.includes('<article>')" v-html="content" ></article>
              <p v-else >
                {{ content }}
              </p>
              <div v-if="role == 'assistant' && resultQuery.length">
                <vs-button class="m-0 my-3" gradient size="small" @click="showMore = !showMore">{{ showMore ? 'Ocultar' : 'Mostrar más' }}</vs-button>
                <ObjectTable :data="resultQuery" v-if="showMore" />
              </div>
    
            </div>
          </li>
          <li v-if="waitingMessage" class="message m-assistant">
            <p class="message-text">
              <i class="fas fa-spinner fa-spin"></i>
            </p>
          </li>
        </div>
        <footer>

        </footer>
      </div>
    </ul>
  </div>
</template>

<style scoped>
.conversation-wrapper {
  border: solid 1px silver;
  border-radius: 0.5rem;
}

.conversation-scale {
  transform: scale(0.95);
}


.chatbot {
  background-color: #050505;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chatbot .title {
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.messages {
  padding: 0;
  margin: 1rem 0;
}

.message {
  list-style: none;
  margin: .25rem 0;
  padding: .5rem .25rem;

}


.m-user {
  display: flex;
  flex-direction: row-reverse;

}

.m-assistant {
  display: flex;
  flex-direction: row;
}

.message-text {
  padding: .25rem 1rem;
  border-radius: 0.5rem;
}

.m-user .message-text {
  border: solid 1px rgb(129, 129, 129);
  max-width: 95%;
}

.m-assistant .message-text {
  background-color: #1c1c1c;
  border: solid 1px #050505;
  max-width: 95%;
}
</style>